/* Animations */
@keyframes InfoPop {
    0% {transform: scale(0.98); opacity: 50%;}
    100% {transform: scale(1); opacity: 100%;}
}

@keyframes FeaturePop {
    0% {transform: scale(0.95);}
    100% {transform: scale(1); opacity: 100%;}
}

/* Home Page */
.HomeContent {
    font-family: 'Inter', sans-serif;
    color: white;
}

/* Layout */
.LayoutContainer {
    margin: auto;
    padding-top: 40px;
    max-width: 900px;
}

/* Info Bubble */
.InfoBubble {
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    height: 260px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: InfoPop 0.2s;
    padding: 20px;
}

.InfoContent {
    max-width: 60%;
}

.AltBoldText {
    color: #00efff;
    font-size: 22px;
    font-weight: bold;
}

.AltText {
    margin-top: 10px;
    font-size: 17px;
    color: #9497B3;
}

.infoButtonContainer {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.infoButton {
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.infoButton#InfoMainButton {
    background-color: #00efff;
    color: #252632;
}

.infoButton#InfoMainButton:hover {
    background-color: rgb(193, 149, 254);
}

.infoButton#InfoAltButton {
    border: 1px solid #00efff;
    color: #00efff;
    background: transparent;
}

.infoButton#InfoAltButton:hover {
    background-color: #00efff;
    color: #252632;
}

/* Features */
.FeatureTitleContainer {
    text-align: center;
    margin-top: 30px;
}

.FeatureTitleText {
    font-size: 28px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9);
}

.FeatureBubbleContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.FeatureBubble {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1f1f1f;
    border-radius: 15px;
    padding: 20px;
    animation: FeaturePop 0.2s;
}

/* Media Queries */
@media (max-width: 768px) {
    .LayoutContainer {
        width: 90%;
    }
    .InfoBubble {
        flex-direction: column;
        height: auto;
        text-align: center;
    }
    .FeatureBubble {
        flex-direction: column;
        text-align: center;
    }
}